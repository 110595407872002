import { NotFound } from '@invisible/common/pages'
import { captureEvent } from '@sentry/nextjs'
import { useEffect } from 'react'

const NotFoundPage = () => {
  useEffect(() => {
    captureEvent({
      message: 'Handled 404',
      tags: { page: '404' },
    })
  }, [])

  return <NotFound />
}

export default NotFoundPage
